@import "styles/variables";
.bid-communication-page {
    .textarea {
        background: #ffffff;
        border: 1px solid #dfe2e6;
        box-sizing: border-box;
        border-radius: 10px;
        resize: none;
        padding: 15px 20px;
        outline: none;

        @media screen and (max-width: 769px) {
            width: 100%;
        }

        &:focus-within {
            border-color: #8d8bdd;
        }
    }

    .btn-padding {
        width: 127px !important;

        @media screen and (max-width: 769px) {
            width: 100% !important;
        }
    }
    .send-box {
        position: relative;
        label {
            font-size: 18px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #8d8bdd;
            font-family: "Averia-regular";
        }

        .textarea {
            width: 65%;
            margin-left: 1.5rem;
            @media screen and (max-width: 769px) {
                width: 100%;
            }
        }

        &::after {
            display: inline-block;
            content: "" "";
            width: 100%;
            height: 0.25em;
            position: absolute;
            bottom: -0.6em;
            left: 0;
            border-bottom: 1px solid $line;
        }
    }

    .submit-box {
        .textarea {
            margin-left: 3.5rem;
            width: 65%;

            @media screen and (max-width: 769px) {
                width: 100%;
                margin-left: 0rem;
            }
        }

        .submit-btn {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 71%;

            @media screen and (max-width: 769px) {
                display: unset;
            }
        }
    }
}
