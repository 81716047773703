@import "styles/variables";

#navbar {
  padding: 0px 30px 0px 10px;

  .nav-dropdown {
    @media screen and (max-width: 768px) {
      left: -90px;
    }
  }

  .avatar {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
  }

  .route_name {
    text-transform: capitalize;
    display: inline-block;
  }
  .backBtn {
    cursor: pointer;
  }

  .logout_tag {
    color: #eb5757;
  }

  @media screen and (max-width: 969px) {
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .hamburger {
    display: none;
    @media screen and (max-width: 969px) {
      display: block;
    }
  }

  .navbar-text {
    font-family: "Averia-bold";
    color: $dark-ash;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6em;
    line-height: 36px;
    padding: 0px;

    @media screen and (max-width: 969px) {
      font-weight: 600;
      font-family: "Averia-regular";
      font-size: 1.375rem;
      line-height: 2.25rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .bellDiv {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    @media screen and (max-width: 969px) {
      display: none;
    }
    .cursor {
      cursor: pointer;
    }
    .coverBell {
      position: relative;
      .notAmount {
        position: absolute;
        top: -5px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: red;
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .notify {
      position: absolute;
      background: #ffffff;
      border: 0.5px solid #e8e8e8;
      box-sizing: border-box;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      z-index: 100;
      width: 536px;
      height: 300px;
      padding: 15px 25px;
      left: -306px;
      top: 50px;
      &::after {
        content: "";
        height: 30px;
        width: 30px;
        background-color: white;
        top: -12px;
        right: 200px;
        border-radius: 6px;
        transform: rotate(45deg);
        position: absolute;
      }
      .allList {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        padding-top: 30px;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background: #f0f2f3;
        }

        &::-webkit-scrollbar {
          height: 7px;
          width: 5px;
          background: #8198b1;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

          background: #8198b1;
        }
        .markAll {
          position: absolute;
          top: 0px;
          right: 10px;
        }
        .greybtn {
          background: rgba(0, 133, 255, 0.1);
          border-radius: 18px;
          color: #505566;
          font-size: 12px;
          padding: 0px 23px;
          outline: none;
          border: none;
        }
        .topSide {
          p {
            color: rgba(0, 0, 0, 0.4);
            font-size: 18px;
            line-height: 16px;
            text-align: center;
          }
          margin-bottom: 20px;
        }
        .taskLists {
          width: 96%;
          margin: auto;
          padding-top: 20px;
          .eachTask {
            width: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .leftSide {
              display: flex;
              align-items: center;
              .circle {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #2f80ed;
                margin-right: 10px;
              }
              .imageCover {
                width: 30px;
                height: 30px;
                background: rgba(0, 133, 255, 0.1);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                img {
                  width: 16px;
                }
              }
              .taskName {
                color: #000000;
                font-size: 15px;
              }
            }
            .rightSide {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .greybtn {
                color: #828282;
                padding: 0px 20px;
              }
              .time {
                display: flex;
                align-items: center;
                margin-left: 50px;
                img {
                  width: 5px;
                  margin-right: 4px;
                }
                p {
                  color: #4f4f4f;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-tab {
    // position: relative;
    .icon {
      margin-right: 10px;
      width: 1.3em;
      &-avatar {
        border: 1px;
        background: #c6c6c6;
        border-radius: 6px;
        margin-right: 10px;
        padding: 0.2rem;
      }
    }

    .user-profile {
      @media screen and (max-width: 768px) {
        margin-top: 1.5rem;
      }
    }

    p {
      font-size: 1em;
      font-weight: 500;
      font-family: "GT Walsheim-medium";
    }
  }
  .arrow-img {
    width: 1.5rem;
    height: 1.7rem;
    align-self: center;
  }
}
