#requisition {
    .container {
        .header {
            font-weight: bold;
            font-size: 23px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #304ba3;
        }

        .stats-card {
            .stats-icon-green {
                background: rgba(0, 176, 79, 0.185);
            }
            .stats-icon-orange {
                background: rgba(242, 153, 74, 0.185);
            }
            .stats-icon-blue {
                background: rgba(45, 156, 219, 0.185);
            }

            .stats-icon-purple,
            .stats-icon-blue,
            .stats-icon-green {
                border-radius: 0px 10px;
                padding: 20px;

                .icon-image {
                    color: #ff7501;
                }
            }
        }

        & .document {
            h3 {
                font-size: 18px;
            }

            .search-field {
                display: flex;
                @media (max-width: 766px) {
                    display: block;
                }
                .custom-select {
                    background: #ffffff;
                    border: 1px solid #e8e8e8;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: fit-content;
                    padding: 0.9rem 1.7rem;
                    padding-bottom: 2.2rem;
                }
            }

            #upload {
                border: 1px solid #dfe2e6;
                border-radius: 10px;
                background: #ffffff;
                border-radius: 10px;
                line-height: 30px;
                color: #737a91;
                padding: 6px 82px;
            }
        }
    }
}
