@import "styles/variables";

#evaluation_4 {
    .container {
        .header-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 1.5rem;
            row-gap: 0.5rem;
           
            .header {
                font-weight: bold;
                font-size: 23px;
                line-height: 36px;
                letter-spacing: -0.02em;
                color: #677ec9;
                margin-top: 1rem;
                margin-right: 4rem;
            }
            #upload {
                border: 1px solid #dfe2e6;
                border-radius: 10px;
                background: #ffffff;
                border-radius: 10px;
                line-height: 30px;
                color: #737a91;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
            .publish-btn {
                background: #00b0a5 !important;
                color: #ffffff !important;
                &.published{
                    opacity: 0.3;
                }
            }
            .invitebtn{
                width: 230px;
            }
        }

        .evaluation {
            .overall-container {
                display: flex;

                h3 {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                    color: #737a91;
                    margin-top: 0px;
                }
                :nth-child(2) {
                    margin-left: 8rem;
                }
                @media (max-width: 766px) {
                    display: block;
                    :nth-child(2) {
                        margin-left: 0rem;
                    }
                }
            }
            hr {
                margin-top: 0px;
                margin-bottom: 0px;
                width: 80%;
            }

            .bid-technical-requirment-page-answers {
                .question-box {
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: column;

                    .question-label {
                        display: flex;
                        font-family: "Averia-regular";
                        font-size: 18px;
                        line-height: 36px;
                        letter-spacing: -0.02em;
                        color: #8d8bdd;
                        position: relative;
                        align-items: center;
                        margin-bottom: 0.7rem;

                        &::after {
                            display: inline-block;
                            content: "" "";
                            width: 100%;
                            height: 0.25em;
                            position: absolute;
                            bottom: -0.8em;
                            left: 0;
                            border-bottom: 1px solid $line;
                        }

                        .rating-header {
                            width: 65%;
                            // margin-right: 20px;
                        }

                        .rating {
                            color: #737a91;
                            margin-left: 10rem;
                            display: flex;
                            position: relative;

                            #upload {
                                border: 1px solid #dfe2e6;
                                border-radius: 10px;
                                background: #ffffff;
                                border-radius: 10px;
                                line-height: 30px;
                                color: #737a91;
                                position: absolute;
                                padding: 4px 20px;
                                top: -1.2rem;
                                left: 300%;
                            }
                        }
                        @media (max-width: 766px) {
                            display: block;

                            .rating-header {
                                width: 100%;
                            }
                            .rating {
                                margin-top: 0.7rem;
                                margin-left: 0rem;

                                #upload {
                                    left: 20%;
                                    position: relative;
                                    top: unset;
                                }
                            }
                        }
                    }

                    .textarea {
                        box-sizing: border-box;
                        resize: none;
                        padding: 15px 20px;
                        width: 70%;
                        outline: none;
                        margin-left: 0.9rem;
                        font-size: 16px;

                        &:focus-within {
                            border-color: #8d8bdd;
                        }

                        @media screen and (max-width: 769px) {
                            width: 100%;
                        }
                    }

                    .radio-box {
                        gap: 0.5rem;
                        margin-left: 0.9rem;

                        &_label {
                            color: $black;
                        }
                    }

                    .upload-box {
                        margin-left: 0.9rem;
                        display: flex;
                        width: 70%;

                        gap: 1rem;
                        align-items: center;
                        @media screen and (max-width: 769px) {
                            width: 100%;
                        }
                        &_label {
                            color: $black;
                        }

                        .fileuploader {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.fileType{
    margin-top: 20px;
    img{
        width: 40px;
    }
}