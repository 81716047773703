#evaluation-3 {
    .container {
        .header {
            font-weight: bold;
            font-size: 23px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #304ba3;
        }
        .charts{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin: 1rem;
            .chart{
                width: 47%;
                height: 337px;
                margin: 1rem 0;
                @media (max-width: 760px) {
                    width: 100%;
                }
            }
        }
        .stats-card {
            .stats-icon-green {
                background: rgba(0, 176, 79, 0.185);
            }
            .stats-icon-purple {
                background: rgba(187, 107, 217, 0.185);
            }
            .stats-icon-blue {
                background: rgba(45, 156, 219, 0.185);
            }

            .stats-icon-purple,
            .stats-icon-blue,
            .stats-icon-green {
                border-radius: 0px 10px;
                padding: 20px;

                .icon-image {
                    color: #ff7501;
                }
            }
        }

        & .evalaution {
            h3 {
                font-size: 18px;
            }

            .search-field {
                display: flex;

                @media (max-width: 766px) {
                    display: block;
                }
            }
            #upload {
                border-radius: 10px;
                border-radius: 10px;
                color: #737a91;
                padding: 0.6rem 1.7rem;
                background: rgba(196, 196, 196, 0.185);
                border: 1px solid rgba(80, 85, 102, 0.185);
                box-sizing: border-box;
                border-radius: 10px;
            }

            .custom-select {
                background: #ffffff;
                border: 1px solid #e8e8e8;
                box-sizing: border-box;
                border-radius: 10px;
                width: fit-content;
                padding: 0.9rem 1.7rem;
                padding-bottom: 2.2rem;
            }
        }
    }
    .analyzeDiv{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .filter{

        }
        .analyze{
            background: #42DF5B;
            color: white;
            padding: 12px 0;
            width: 120px;
            margin-top: 4px;
            margin-left: 20px;
            border: transparent;
            border-radius: 10px;
        }
    }
    
.page-wrap {
    overflow-x: auto;
    .table-wrap {
        width: max-content;
        min-width: 100%;
      }
      
      table {
        width: 100%;
        border-spacing: 0;
        min-width: 700px;
      
        .table-heading {
          background: rgba(47, 128, 237, 0.2);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
      
          th {
            border: none;
            padding-top: 1rem;
            padding-bottom: 1rem;
      
            &:first-child {
              padding-left: 5rem;
            }
          }
      
          .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
      
            label {
              font-weight: 500;
              margin-bottom: 0;
            }
            input {
              margin-right: 0.3rem;
            }
          }
        }
      
        thead,
        tfoot {
          th {
            color: black;
            border-bottom: 1px solid #ccc;
          }
        }
        td.empty,
        th.empty {
          border: none;
          width: 150px;
        }
      
        th,
        td {
          padding: 0.7rem 1rem;
          text-align: center;
          &:first-child {
            text-align: left;
            width: 35vw;
            //   min-width: ;
            word-wrap: unset;
            white-space: nowrap;
            padding-left: 0;
          }
      
          &:last-child,
          &:first-child {
            padding-left: 3rem;
            padding-right: 3rem;
          }
        }
        td:first-child {
          padding-left: 4rem;
          color: rgb(33, 115, 240);
          font-weight: 500;
        }
      
        tfoot tr:last-child td {
          border-bottom: 1px solid #ccc;
          &.empty {
            border: none;
          }
        }
      }
      
      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 3rem;
        margin-right: 3rem;
      
        .btn {
          padding: 0.7rem;
          min-width: 120px;
          background-color: transparent;
          border: 1px solid #ccc;
          font-weight: 500;
          border-radius: 10px;
          margin: 0.5rem;
        }
      }
  }

  
}
