@import "styles/variables";
.master-data {
  .container {
    @media screen and (max-width: 769px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  .tab-container {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 0.625rem;
    min-height: 39rem;
    padding-left: 2rem;
    padding-right: 5rem;

    .tabs-page {
      margin-top: -1.5rem;
    }

    @media screen and (max-width: 769px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
  }

  .page-title {
    color: #3d59b7;
    font-size: 23px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 1rem;
    font-family: "Averia-bold";
  }
}
.hover-a:hover {
  color: #3d59b7;
}
