@import "styles/variables";

#bid-page {
    .container {
        margin-right: 0px;
        margin-left: 0px;
        max-width: 100%;
    }

    .page-title {
        font-family: "Averia-bold";
        font-style: normal;
        font-weight: bold;
        font-size: 18em / $scale;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .span-container {
        display: flex;

        .edit {
            border-right: 1px solid #737a91;
            padding-right: 10px;
            width: fit-content;
            color: #737a91;
            cursor: pointer;
        }
        .edit-n {
            // border-right: 1px solid #737a91;
            padding-right: 10px;
            width: fit-content;
            color: #737a91;
            cursor: pointer;
        }
        .delete {
            padding-right: 10px;
            width: fit-content;
            color: $yara-red;
            cursor: pointer;
        }
    }

    .search-field {
        display: flex;
        @media (max-width: 576px) {
            display: block;
        }
    }

    .stats-icon-orange {
        background: $orange;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: #ff7501;
        }
    }
    .stats-icon-leaf {
        background: $community;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: white;
        }
    }
    .stats-icon-cash {
        background: $cash;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: white;
        }
    }
    .stats-icon-folder {
        background: $folder;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: white;
        }
    }

    .search {
        background: #ffffff;
        border: 1px solid $line;
        border-radius: 10px;
        font-size: 14em / $scale;
        line-height: 30px;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            font-family: "GT Walsheim-light";
        }
    }
    .button-position {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 6px 20px;
    }
    .button-container {
        display: flex;
    }
    .arrow-div {
        @media screen and (max-width: 769px) {
        }
    }
    .box-scroll {
        max-height: 17rem;
        overflow-y: scroll;
    }
}
