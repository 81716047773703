@import "styles/variables";

.modal-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
    background-color: "";
    z-index: 9000000;
    display: flex;
    align-items: center;
    justify-content: center;
    .line{
      margin: 0;
    }
    .colored-ul{
      color: black;
      
      margin-left: 20px;
      height: 160px;
      overflow-y: auto;
       opacity: 0.8;
       font-size: 18px;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    
        background: #f0f2f3;
      }
    
      &::-webkit-scrollbar {
        height: 7px;
        width: 5px;
        background: #8198b1;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    
        background: #C4C4C4;
      }
    }
    .black{
      color: black;
    }

    .backDrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        backdrop-filter: blur(3px);
    }

    .contentwrapper {
        background-color: #fff;
        z-index: 3;
        background: #ffffff;
        border: 0.6px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 0px 7px rgba(61, 181, 74, 0.09);
        width: 80%;
        max-width: 401px;
        height: 100%;
        height: 700px;
        // position: relative;

        & .modal_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 30px 25px 30px;
            background: #ffffff;
            box-shadow: 0px 1px 6px rgba(188, 188, 188, 0.4);

            h4 {
                font-family: "Averia-regular";
                font-style: normal;
                font-weight: 500;
                font-size: 19px;
                line-height: 30px;
                color: $black;
                &.faint{
                    color: rgba(0, 0, 0, 0.3);
                }
                &.bold{
                    color: $black;
                }
            }
        }

        & .modal_content {
            padding: 25px 30px 25px 30px;
            // max-height: 40rem;
            height: 90%;
            background: #ffffff;
            overflow-y: auto;
        }
    }
    .justify{
        justify-content: space-between;
        width: 90%;
    }

    /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 18px;
      /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input:checked + .slider {
        background-color: #2F80ED;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2F80ED;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(48px);
        -ms-transform: translateX(48px);
        transform: translateX(48px);
      }
      
    /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00B01C;
    -webkit-transition: .4s;
    transition: .4s;
    &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        top: -17%;
        background-color: #00B01C;
        -webkit-transition: .4s;
        transition: .4s;
      }
    
      &.active{
        background-color: #2F80ED;
        &::before{
            background-color: #2F80ED;
        }
      }
  }
  }
  

  

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .template{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #2649BC;
    margin: 40px 0;
    text-align: center;
  }
}
