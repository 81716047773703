.catalogue {
    & header {
        display: flex;

        h3 {
            font-size: 17px;
        }
    }

    & .document {
        h3 {
            font-size: 18px;
        }

        & .nav-btn {
            background: #ffffff;
            border: 1px solid #dfe2e6;
            border-radius: 10px;
            width: 120px;
            height: 52px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;

            &:hover {
                background-color: #e8e8e8;
            }
        }
    }

    .table-img {
        display: block;
        margin-left: 1rem;
        width: 22px;
    }

    & .action-btn {
        background: #dbe7f7;
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        width: 77px;
        height: 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        color: #2f80ed !important;
        font-size: 15px;

        &:hover {
            background-color: #d3d8f3;
        }
    }
}

#upload {
    border: 1px solid #dfe2e6;
    border-radius: 10px;
    background: #ffffff;
    border-radius: 10px;
    line-height: 30px;
    color: #737a91;
    padding: 4px 32px;
}

.search-field {
    display: flex;
    @media (max-width: 766px) {
        display: block;
    }
}

.middle-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    .icon-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
    }
}
